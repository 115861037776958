<template>
  <div>
    <el-form-item label="提示文字">
      <el-input size="small" v-model="value.placeholder" placeholder="请设置提示语" />
    </el-form-item>
    <el-form-item label="选项设置">
      <el-cascader :options="selectList" @change="handleChange" v-model="notset" disabled
        placeholder="未设置" size="mini"  :show-all-levels="false">
      </el-cascader>
    </el-form-item>
    <el-form-item label="选项展开">
      <el-switch v-model="value.expanding"></el-switch>
    </el-form-item>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getPayWay } from "@/api/design";

export default {
  name: "PayInputConfig",
  components: { draggable },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      notset: '4',
      selectList: [
        {
          value: '2',
          label: '系统数据',
          children: [{
            value: '3',
            label: '档案',
            children: [
              {
              value: '4',
              label: '支付方式'
              }
          ]},]
        }
      ],
      dragOption: {
        animation: 300,
        sort: true
      }
    }
  },
  mounted() {
    this.handleChange();
  },
  methods: {
    handleChange() {
      getPayWay().then(res => {
          this.value.options = res;
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .options {
  .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .el-icon-rank {
    padding-right: 5px;
    cursor: move;
  }

  .option-item {
    .el-input {
      width: 250px;
      float: right;
    }
  }
}

.option-item-label {
  height: 30px;
  line-height: 30px;

  button {
    float: right;
  }
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/
</style>
